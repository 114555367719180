<template>
  <div class="knowledge-base knowledge-base-blogs relative landing-page-wrapper new-langing-ui">
    <landing-page-header
      class="customer-landing-page-header"
      :nav-class="'light'"
    />
    <!-- search input -->
    <section
      id="knowledge-base-search"
      class="content-info unset-padding-responsive no-white-space"
    >
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{ backgroundImage: `url(${require('@/assets/images/banner/banner.png')})` }"
      >
        <b-card-body class="card-body">
          <!-- form -->
          <b-form class="kb-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="knowledgeBaseSearchQuery"
                placeholder="Ask a question...."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <section id="knowledge-base-blogs-content">
      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
          v-for="(data, index) in filteredKB"
          :key="index"
          md="4"
          sm="6"
        >
          <b-card>
            <h6 class="kb-title">
              <img
                class="mr-50"
                width="20"
                :src="$helpers.imageHelper(data.picture)"
                @error="$helpers.imageError"
              >{{ data.title }} ({{ data.category.questions.length }})
            </h6>
            <b-list-group class="list-group-circle mt-2">
              <b-list-group-item
                v-for="que in data.category.questions"
                :key="que.id"
                class="text-body"
                :to="getViewUrl(que)"
              >
                {{ que.question }}
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
        <b-col
          v-show="!filteredKB.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            {{ $t('Search result not found') }}!!
          </h4>
        </b-col>
      </b-row>
    </section>
    <landing-page-footer />
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BListGroup, BListGroupItem } from 'bootstrap-vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import { showDangerNotification, showErrorNotification, showLoader, hideLoader } from '@/@core/comp-functions/ui/app'
import { useUserUi } from './users/useUser'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    LandingPageHeader,
    LandingPageFooter,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      baseId: '',
      kb: [],
    }
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.kb.filter(
        item => (item.title && item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower)) || (item.description && item.description.toLowerCase().includes(knowledgeBaseSearchQueryLower)),
      )
    },
  },
  created() {
    this.baseId = this.$route.params.baseId
    const { knowledgeBaseList } = useUserUi()
    showLoader()
    knowledgeBaseList(`&category_id=${this.baseId}`)
      .then(({ data }) => {
        if (data.statusCode === '200') {
          // eslint-disable-next-line eqeqeq
          this.kb = data.responseData.filter(x => x.category_id == this.baseId)
        } else {
          showDangerNotification(this, data.message)
        }
        hideLoader()
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
  },
  methods: {
    getViewUrl(blog) {
      return `/knowledge-base/details/${blog.question
        .trim()
        .toLowerCase()
        .replace(/ /g, '-').replace(/[^a-zA-Z- ]/g, '')}/${blog.category_id}/${blog.id}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>

<style scoped>
.landing-page-wrapper .content-info {
  padding-top: 6rem;
}

#knowledge-base-blogs-content {
  padding-right: 80px;
  padding-left: 80px;
}

@media only screen and (max-width: 767px) {
  #knowledge-base-blogs-content {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing-page.scss';
</style>
